
<template>
  <div>
    <template
      v-if="list.length > 0"
    >
      <div class="application-components-swiper-wrapper">
        <swiper
          class="application-components-swiper"
          :options="swiperOptions"  
        >
          <template
            v-for="(item, index) in list"
          >
            <swiper-slide
              :key="index"
              class="application-slide"
              @click.native="imgClick(index)"
            > 
              <div
                class="application-slide-img"
              >
                <img
                  :src="item.fullLink"
                />
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <img-prew-view
        v-if="imgPrewShow"
        :url-list="list.map(ele => ele.fullLink)"
        :initial-index="imgActive"
        :on-close="onclose"
      />
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ImgPrewView from '@/components/image/image-viewer.vue'
export default {
  name: 'ApplicationComponsntsSwiper',
  components: {
    Swiper,
    SwiperSlide,
    ImgPrewView
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imgPrewShow: false,
      imgActive: 0,
      swiper: null,
      swiperOptions: {
        spaceBetween: 10,
        slidesPerView: 'auto',
      }
    }
  },
  methods: {
    imgClick(index) {
      this.imgActive = index
      this.imgPrewShow = true
      document.body.style.overflow='hidden'
    },
    onclose() {
      this.imgPrewShow = false
      document.body.style.overflow='auto'
    }
  }
}
</script>

<style lang="scss" scoped>
.application-components-swiper-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 20px;
  padding-top: 0;
  .application-components-swiper {
    width: 100%;
    height: 173px;
    .application-slide {
      width: 300px;
      .application-slide-img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        align-items: center;
        margin-bottom: 20px;
        .el-image {
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>