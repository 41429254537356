<template>
  <div class="application-components-wrapper">
    <div class="title">
      {{ $t(prop).title }}
    </div>
    <application-swiper
      :list="computedList"
    />
    <div class="application-components-home">
      <div class="text">
        {{ $t(prop).des}}
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationSwiper from './swiper/index.vue'
export default {
  name: 'applicationComponentsPublic',
  components: {
    ApplicationSwiper
  },
  props: {
    prop: {
      type: String,
      default: 'applicationHomeObject'
    },
    identification: {
      type: String,
      default: '应用案例-家庭'
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoad: false,
    }
  },

  computed: {
    computedList() {
      return this.list.filter(e => e.identification === this.identification).map(ele => {
        const result = ele
        result.fullLink =  process.env.VUE_APP_API + ele.path
        return result
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.application-components-wrapper {
  width: 100%;
  padding-bottom: 30px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #222222;
    line-height: 36px;
    text-align: center;
    margin: 20px 0;
  }
  .application-components-home {
    font-size: 13px;
    color: #666666;
    line-height: 24px;
    padding: 0 20px;
  }
}
</style>